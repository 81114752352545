<template>
  <div class="platform">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide class="current1" style="height: 11rem;">
        <div class="nav">
          <div class="title">The GV20 STEAD Platform</div>
          <p class="introduction">＊ Simultaneous Target Evaluation and Antibody Discovery</p>
          <p class="introduction">Integrating computation and experimentation to unlock the power of the human immune system</p>
        </div>
      </swiper-slide>
    </swiper>
    <div class="text-info">
      <div class="bg-white">
        <div data-swiper-parallax="-200" class="content">
          <div class="list"><span>Cancer patients' immune systems recognize relevant tumor targets and mount antibody responses.</span></div>
          <div class="list"><span>These antibodies are insufficient to cure the tumors potentially due to low abundance and sub-optimal antibody format.</span></div>
          <div class="list"><span>With the right dose and format, these antibodies can be powerful anti-tumor therapeutics.</span></div>
          <div class="list"><span>GV20's proprietary STEAD platform discovers novel therapeutic targets and antibodies by harnessing patients' immune responses.</span></div>
          <a href="../assets/platform/platform-img4.png"><img src="../assets/platform/platform-img4.png" style="position: relative; left: 20px; margin: 20px auto;width: 90%;"></a>
          <div class="list"><span><b>AI-driven target and antibody identification</b>: Our platform computationally extracts antibodies from trillions of patient tumor RNA-seq reads. These large datasets are processed by our proprietary AI model to pair targets and corresponding antibodies in silico, de novo with speed and scale.</span></div>
          <div class="list"><span><b>Target prioritization and validation</b>: Our team of world-renowned experts in genomics and computational biology leverage in-house and public genomics data and other big data sources to generate insights and de-risk targets identified from patient tumors.</span></div>
          <div class="list"><span><b>Antibody synthesis and experimental validation</b>: We test the antibodies predicted by our proprietary AI model using a rigorous suite of in vitro assays and in vivo models.</span></div>
          <br>
          <p>GV20's STEAD platform has generated novel targets and antibodies in Oncology. Our lead program, GV20-0251, is a first-in-class antibody against a novel immune checkpoint IGSF8. Our platform is continuing to yield novel targets and antibodies to augment internal pipeline and enable partnerships with pharmaceutical companies.</p>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,//显示个数
        pagination: {
          el: '.swiper-pagination'
        },
        effect: 'fade',
        parallax: true,
      }
    }
  },
  components: {
    Footers
  }
}
</script>
<style lang="scss" scoped>
$padding-left: 50px;
.content {
  color: #000;
  font-size: 20px;
  margin: 1rem 0.5rem;
  .list {
    &::before {
      content: '⛁';
      font-size: 24px;
      margin-right: 10px;
    }
    span {
      width: 94%;
      vertical-align: text-top;
      display: inline-block;
    }
  }
}
.platform {
  [class^='current'] {
    height: 2074px;
    padding: 0 20px;
  }
  .current1 {
    /*background: url(../assets/platform/headline-bg1.png) no-repeat center
      center/cover;*/
    background: url(../assets/platform/headline-bg1.png);
    .data {
      background: url(../assets/platform/1.png) no-repeat center center/cover;
    }
    .bg {
      background-color: #3852a3;
    }
    .bg_color {
      background-color: #77a8d2;
    }
  }
  .current2 {
    background: url(../assets/platform/headline-bg2.png) no-repeat center
      center/cover;
    .data {
      background: url(../assets/platform/2.png) no-repeat center center/cover;
    }
    .bg {
      background-color: #2991d6;
    }
    .bg_color {
      background-color: #2991d652;
    }
  }
  .current3 {
    background: url(../assets/platform/headline-bg3.png) no-repeat center
      center/cover;
    .data {
      background: url(../assets/platform/3.png) no-repeat center center/cover;
    }
    .bg {
      background-color: #49c9d4;
    }
    .bg_color {
      background-color: #8bc8da;
    }
  }

  .data {
    display: flex;
    height: 476px;
    .left {
      margin-top: 120px;
      flex: 0 0 30%;
      font-size: 40px;
    }
    .right {
      flex: 0 0 70%;
    }
  }
  .list {
    padding: 2px 20px;
  }
  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 20px 0;
    .text {
      font-size: 30px;
      margin-top: 10px;
    }
    img {
      width: 100px;
    }
  }
  .nav {
    padding: 70px 60px 0 $padding-left;
    color: #fff;
    .title {
      font-size: 60px;
      padding-bottom: 50px;
    }
    .introduction {
      font-size: 40px;
    }
  }
  /deep/ .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.8);
  }
  /deep/ .swiper-pagination-bullet-active {
    background: rgba(255, 255, 255, 1);
  }
  .text_info {
    font-size: 40px;
    padding-bottom: 150px;
    & > div {
      padding: 160px 44px;
    }
    img {
      width: 100%;
      margin: 40px 0;
    }
    .cr1 {
      color: #3852a3;
    }
    .title {
      font-size: 50px;
      font-weight: bold;
      margin: 20px 0;
    }
    .text {
      font-weight: 600;
    }
    .bg1 {
      background: #f6f8f9;
      padding-bottom: 40px;
    }
  }
}
</style>
